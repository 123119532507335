import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  getCountryList,
  getUserDetails,
  otpVarification,
  sendOtp,
  sendEmailOtp,
  userLogin,
  userRegistration,
} from "actions/auth";
import Signup from "components/public-dashboard/features/signup";
import {
  EMAIL_TIMEOUT_TIME,
  LOGIN_SUCCESS,
  OTP_VARIFY_FAILURE,
  OTP_VARIFY_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_SUCCESS,
  SIGNUP_SUCCESS, SMS_TIMEOUT_TIME, USER_TIMEOUT_PHONE, VERIFICATION_EMAIL_TIMEOUT_CODE, VERIFICATION_SMS_TIMEOUT_CODE,
} from "constants/actionTypes";
import history from "history/history";
import {
  removeUserAuth,
  removeUserDetail,
  setToken,
} from "middleware/storage";
import {setLoading, showSnackBar} from "reducers/uiGlobal";
import auth from "routing/checkAuth";
import {checkEmailRegex} from "components/utils"
import moment from "moment/moment";

class Auth extends PureComponent {
  constructor(props) {
    super(props);
    const expirationTimes = this.getRestoreExpirationTime();
    this.state = {
      mobNum: expirationTimes.phone,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPwd: "",
      countryId: 0,
      isChecked: false,
      isVisible: false,
      otp: "",
      username: "",
      error: "",
      tosError: "",
      otpError: "",
      referral: "",
      isRegDisable: true,
      receivePromo: true,
      otpType: "email",
      emailVerificationCode: expirationTimes.emailCode, // used if user refreshes page and not exist in memory
      smsVerificationCode: expirationTimes.smsCode, // used if user refreshes page and not exist in memory
      lastEmailOtpExpirationTime: expirationTimes.email,
      lastSMSOtpExpirationTime: expirationTimes.sms,
      timeoutDuration: 2,

    };
    this.props.dispatch(getCountryList()).then((res) => {});
  }

  updateLoading = (loading) => {
    this.props.dispatch(setLoading(loading));
  };

  onChangeFormFieldPhone = (event) => {
    this.setState({ ...this.state, countryId: event.target.value });
    this.setState({
      mobNum: this.props.countries.find(
        (country) => (country.value = event.target.value)
      ).phoneCode,
    });
    this.setState({ isRegDisable: event.target.value > 0 ? false : true });
  };

  checkEmail = (email) => {
    let result = checkEmailRegex(email);
    return result;
  };

  validateSignUpForm = () => {
    const { mobNum, firstName, lastName, email, password, confirmPwd, referral, isChecked } =
      this.state;


    if (mobNum.length != 11) {
      this.setState({
        error:
          "Your mobile number must be at least 11 digits. E.g 1(868) 333-4444 ",
      });
      return false;
    }
    if (firstName === "") {
      this.setState({
        error: "First Name cannot be empty",
      });
      return false;
    } else if (/[^a-zA-Z-'\s]/.test(firstName)) {
      this.setState({
        error: "First Name is invalid",
      });
      return false;
    }

    if (lastName === "") {
      this.setState({
        error: "Last Name cannot be empty",
      });
      return false;
    } else if (/[^a-zA-Z-'\s]/.test(lastName)) {
      this.setState({
        error: "Last Name is invalid",
      });
      return false;
    }

    let emailRes = this.checkEmail(email);
    if(!emailRes){
      this.setState({
        error:
            "Email invalid. Only the following special characters are allowed period (.), underscore (_), hyphen (-).",
      });
      return false;
    }

    const validPassword = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );
    if (password.match(validPassword) == null) {
      this.setState({
        error:
          "Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
      });
      return false;
    }

    if (password !== confirmPwd) {
      this.setState({ error: "Your password entries do not match." });
      return false;
    }

    const validReferralCode = new RegExp(
      "^[A-Za-z0-9- ]+$"
    );
    if (referral.length > 0) {
      if (referral.match(validReferralCode) == null) {
        this.setState({ error: "Invalid Referral Code" });
        return false;
      }
    }

    if(!isChecked){
      this.setState({ tosError: "Agree to the terms and conditions." });
      return false;
    }

    return true;
  };

  setMobileOtp = (digit) => {
    this.setState({ otp: digit });
  };

  onOtpVarify = () => {
    this.setState({ otp: "" });
    this.setState({ otpError: "" });
    const { otp, otpType, mobNum, smsVerificationCode, emailVerificationCode } = this.state;

    const { smsCred } = this.props;
    if (otp.length === 6) {
      const cred = {
        phone: smsCred.phone || mobNum,
        tempVerificationCodeId: otpType === "sms" ? smsVerificationCode : emailVerificationCode,
        sMSCode: otp,
      };
      removeUserAuth();
      removeUserDetail();
      this.updateLoading(true);

      this.props.dispatch(otpVarification(cred)).then((res) => {
        if (res.type === OTP_VARIFY_SUCCESS) {
          this.updateLoading(false);
          this.setState({ otpError: "" });
          this.updateExpirationTime(0, cred.phone, smsCred.tempVerificationCodeId, false, true);
          this.register();
        }

        if (res.type === OTP_VARIFY_FAILURE) {
          this.updateLoading(false);
          this.setState({ otpError: res.error });
        }
      });
    } else {
      this.updateLoading(false);
      this.setState({ otpError: "Please complete the OTP" });
    }
  };


  updateExpirationTime = (minutes, phone, verificationCode, shouldReset = false, resetAll = false) =>{
    const { otpType } = this.state;

    if(resetAll){
      localStorage.removeItem(SMS_TIMEOUT_TIME);
      localStorage.removeItem(EMAIL_TIMEOUT_TIME);
      localStorage.removeItem(USER_TIMEOUT_PHONE);
      localStorage.removeItem(VERIFICATION_SMS_TIMEOUT_CODE);
      localStorage.removeItem(VERIFICATION_EMAIL_TIMEOUT_CODE);
      this.setState({ lastEmailOtpExpirationTime: null, lastSMSOtpExpirationTime: null,  emailVerificationCode: "", smsVerificationCode:""  });

      return;
    }

    if(shouldReset){
      if(otpType == "sms")
        this.setState({ lastSMSOtpExpirationTime: null });
      else
        this.setState({ lastEmailOtpExpirationTime: null });
    }

    const otpExpiration = moment().add(minutes, 'minutes');
    if(otpType === "sms"){
      localStorage.setItem(SMS_TIMEOUT_TIME, otpExpiration.toISOString());
      localStorage.setItem(VERIFICATION_SMS_TIMEOUT_CODE, verificationCode);
      this.setState({ lastSMSOtpExpirationTime: otpExpiration, smsVerificationCode: verificationCode });
    }
    else{
      localStorage.setItem(EMAIL_TIMEOUT_TIME, otpExpiration.toISOString());
      localStorage.setItem(VERIFICATION_EMAIL_TIMEOUT_CODE, verificationCode);
      this.setState({ lastEmailOtpExpirationTime: otpExpiration, emailVerificationCode: verificationCode });
    }

    localStorage.setItem(USER_TIMEOUT_PHONE, phone);
  }



  getRestoreExpirationTime = () => {
    let smsTimeout = localStorage.getItem(SMS_TIMEOUT_TIME);
    let userPhone =  localStorage.getItem(USER_TIMEOUT_PHONE);
    let emailTimeout = localStorage.getItem(EMAIL_TIMEOUT_TIME);
    let verificationEmailCode = localStorage.getItem(VERIFICATION_EMAIL_TIMEOUT_CODE);
    let verificationSMSCode = localStorage.getItem(VERIFICATION_SMS_TIMEOUT_CODE);

    return {
      sms : EMAIL_TIMEOUT_TIME ? moment(smsTimeout) : null,
      email : SMS_TIMEOUT_TIME ? moment(emailTimeout) : null,
      phone: userPhone || "",
      smsCode: verificationSMSCode ? parseInt(verificationSMSCode) : "",
      emailCode: verificationEmailCode ? parseInt(verificationEmailCode) : ""
    }
  }

  resendOtp = () => {
    this.setState({ otp: "" });
    this.setState({ otpError: "" });
    const { mobNum, email,otpType } = this.state;
    let cred = { phone: mobNum, email: email };
    this.updateLoading(true);
    this.props.dispatch(otpType === "sms" ?  sendOtp(cred) : sendEmailOtp(email, cred.phone)).then((res) => {
      if (res.type === RESEND_OTP_SUCCESS) {
        this.updateExpirationTime(res.entities.user.smsCred.timeoutDuration, cred.phone, res.entities.user.smsCred.tempVerificationCodeId, true);

        this.updateLoading(false);
      }
      if (res.type === RESEND_OTP_FAILURE) {
        this.updateLoading(false);
        this.setState({ error: res.error });
      }
    });
  };



  setOtpType = (type) =>{
    this.setState({ otpType: type,});
  }


  sendEmailOtp = (email, mobile) =>{
    const {lastEmailOtpExpirationTime } = this.state;
    let currentTime = moment();
    if(lastEmailOtpExpirationTime !== null && currentTime.isBefore(lastEmailOtpExpirationTime)){
      return;
    }
    this.props.dispatch( sendEmailOtp(email, mobile)).then((res) => {
      if(res.type === RESEND_OTP_SUCCESS){
        this.updateExpirationTime(res.entities.user.smsCred.timeoutDuration, mobile, res.entities.user.smsCred.tempVerificationCodeId);
      }else if (res.type === RESEND_OTP_FAILURE) {
        this.props.dispatch(showSnackBar({
          msg: "There was an issue sending the OTP code",
          type: "error",
          autoHideDuration: 30000,
        }))
      }
    });
  }

  sendSMSOtp = (phone) => {
    const {lastSMSOtpExpirationTime } = this.state;
    let currentTime = moment();
    if(lastSMSOtpExpirationTime !== null && currentTime.isBefore(lastSMSOtpExpirationTime)){
      return;
    }
    this.props. dispatch(sendOtp({Phone: phone})).then((res) => {
      if(res.type === RESEND_OTP_SUCCESS){
        this.updateExpirationTime(res.entities.user.smsCred.timeoutDuration, phone,  res.entities.user.smsCred.tempVerificationCodeId);
      }else if  (res.type === RESEND_OTP_FAILURE) {
        this.props.dispatch(showSnackBar({
          msg: "There was an issue sending the OTP code",
          type: "error",
          autoHideDuration: 30000,
        }))
      }
    });
  }


  register = () => {
    const {
      mobNum,
      firstName,
      lastName,
      email,
      password,
      countryId,
      referral,
      receivePromo
    } = this.state;

    const user = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: mobNum,
      countryId: countryId,
      password: password,
      referralCode: referral,
      receivePromotions: receivePromo
    };
    this.updateLoading(true);
    this.props.dispatch(userRegistration(user)).then((res) => {
      if (res.type === SIGNUP_SUCCESS) {
        const userLog = {
          username: email,
          password: password,
        };
        this.props.dispatch(userLogin(userLog)).then((res) => {
          const { tokens } = this.props;
          if (res.type === LOGIN_SUCCESS && tokens) {
            this.setState({ error: "" });
            setToken(tokens.token);
            auth.giveAuthAccess();
            this.props.dispatch(getUserDetails(tokens.token)).then((res) => {
              this.updateLoading(false);
              history.push("/user", { isAuth: true });
            });
          } else {
            this.updateLoading(false);
            this.setState({ otpError: res.error });
          }
        });
      } else {
        this.updateLoading(false);
        this.setState({ otpError: res.error });
      }
    });
  };

  setPopupVisibility = () => {
    const { isVisible } = this.state;
    this.setState({ isVisible: !isVisible, otp: "" });
  };

  validateCheckBox = (e) => {
    const { isChecked } = this.state;
    this.setState({ isChecked: !isChecked });
  };

  onChangeFormField = (field, value) => {
    this.setState({ ...this.state, [field]: value });
  };

  onSubmit = (e) => {
    const {
      mobNum,
      firstName,
      lastName,
      email,
      password,
      countryId,
      referral,
      isChecked,
      receivePromo,
      lastEmailOtpExpirationTime,
    } = this.state;
    e.preventDefault();
    this.setState({ error: "" });
    this.setState({ tosError: "" });
    this.setState({ otpError: "" });

    if (this.validateSignUpForm()) {
      const user = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: mobNum,
        countryId: countryId,
        password: password,
        referral: referral,
        receivePromotions: receivePromo
      };

      this.setState({ email: user.email });

      ////--------------------------
      let cred = {phone: user.phone, email: user.email};
      this.updateLoading(true);
      let currentTime = moment();
      if (lastEmailOtpExpirationTime !== null && currentTime.isBefore(lastEmailOtpExpirationTime)) {
        this.setState({isVerify: true});
        this.setPopupVisibility();
        this.updateLoading(false);
      } else {
        this.props.dispatch(sendEmailOtp(cred.email, cred.phone)).then((res) => {
          if (res.type === RESEND_OTP_SUCCESS) {
            this.updateLoading(false);
            if (res.entities.user.smsCred.twoFactor) {

              this.updateExpirationTime(res.entities.user.smsCred.timeoutDuration, cred.phone,  res.entities.user.smsCred.tempVerificationCodeId );

              this.setState({
                mobNum: cred.phone,
                timeoutDuration: res.entities.user.smsCred.timeoutDuration,
              });

              this.setState({timeoutDuration: res.entities.user.smsCred.timeoutDuration})
              this.setPopupVisibility();
            } else {
              this.register();
            }
          }
          if (res.type === RESEND_OTP_FAILURE) {
            this.updateLoading(false);
            this.setState({error: res.error});
          }
        });
      }
    }
  };

  render() {
    return (
      <Signup
        {...this.props}
        {...this.state}
        setOtpType={this.setOtpType}
        sendEmailOtp={this.sendEmailOtp}
        sendSMSOtp={this.sendSMSOtp}
        onOtpVarify={this.onOtpVarify}
        resendOtp={this.resendOtp}
        setMobileOtp={this.setMobileOtp}
        onChangeFormField={this.onChangeFormField}
        onChangeFormFieldPhone={this.onChangeFormFieldPhone}
        validateCheckBox={this.validateCheckBox}
        onSubmit={this.onSubmit}
        setVisibility={this.setPopupVisibility}
      />
    );
  }
}

function mapStateToProps(state) {
  const { authReducer, entities } = state;
  return {
    authReducer,
    tokens: entities.user.tokens,
    smsCred: entities.user.smsCred,
    details: entities.user.details,
    countries: entities.user.countries,
    validString: entities.user.queryString,
  };
}

export default connect(mapStateToProps)(Auth);
