import { Container, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import paymentSolutionsImage from "assets/images/YOOZ-payment-solutions-image.png";
import signUp_img from "assets/images/home_content_2.png";
import ContentImageBlock from "components/public-dashboard/components/contentImageBlock";
import ContentList from "components/public-dashboard/components/contentList";
import PrimaryText from "components/public-dashboard/components/primaryText";
import { gatewayFeatures } from "./constants";
import { API_ROOT } from "constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  media: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  featureImg: {
    [theme.breakpoints.up("md")]: {
      height: 110,
      width: 110,
    },
    height: 30,
    width: 30,
  },
  gatewayFeatures: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 40,
      marginBottom: 48,
    },
    paddingLeft: 16,
  },
}));

export default function PartnersContent(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Container>
      <Fragment>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item container xs={12} sm={12}>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              className={classes.gatewayFeatures}
            >
              {gatewayFeatures.map((x, index) => (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  key={"gateway-features-" + index}
                  spacing={2}
                  style={{ paddingBottom: theme.spacing(3) }}
                >
                  <Grid item xs={2}>
                    <img src={x.img} className={classes.featureImg}></img>
                  </Grid>
                  <Grid item xs={10}>
                    <PrimaryText text={x.text} />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item sm={12} md={8}>
                <ContentList
                  title="SECURE PLATFORM"
                  items={[
                    <span>
                      <strong className={classes.magentaText}>
                        YOOZ Payment Gateway
                      </strong>{" "}
                      operates on a secure online platform that integrates with
                      banking systems and card networks like VISA and
                      MasterCard, to seamlessly accept, validate and process
                      your customers’ payment information in near real-time.
                    </span>,
                    <span>
                      <span style={{ color: "#FBAD18", fontWeight: "bold" }}>
                        3D Secure 2 certified
                      </span>{" "}
                      and{" "}
                      <span style={{ color: "#FBAD18", fontWeight: "bold" }}>
                        PCI-DSS compliant
                      </span>{" "}
                      <strong>YOOZ </strong>
                      employs financial best practices, encryption technology
                      and built-in fraud checks so your customers can rest
                      assured that their banking information is protected, and
                      their payments are hassle-free.
                    </span>,
                  ]}
                />
              </Grid>

              <Grid item sm={12} md={4}>
                <video controls className={classes.media}>
                  <source src={`${API_ROOT}document/yo-payment-gateway-vid`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item sm={12} md={8}>
                <ContentList
                  title="Simplified Integration"
                  items={[
                    <span>
                      <strong className={classes.magentaText}>
                        Co-branded API-driven Hosted Page
                      </strong>{" "}<br/>
                      Have a website or e-commerce site but it's not equipped to
                      accept card payments on checkout?
                    </span>,
                    <span>
                      Then our <strong>YOOZ</strong> Hosted Payment Page option
                      is just what you need as it will redirect your customers
                      to a <strong>YOOZ</strong> payment page co-branded with
                      your logo or wordmark.
                    </span>,
                  ]}
                />
              </Grid>

              <Grid item sm={12} md={4}>
                <img
                  src={paymentSolutionsImage}
                  alt="Title Image"
                  className={classes.media}
                ></img>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ContentList
              title="HOW MUCH DOES IT COST? "
              items={[
                <span>
                  <strong>YOOZ Payment Gateway</strong> pricing is extremely
                  competitive and designed with small businesses in mind. There
                  are no sign-up fees, hidden fees or monthly subscriptions.
                </span>,
                <span>
                  We operate on a per transaction pricing model, which means you
                  pay only when you get paid!
                </span>,
                <span>
                  Our standard transaction fees are 3% (bank fees) + $5.63 per
                  transaction. Collections are remitted via ACH at a cost of $1.00 per bulk
transfer, 3 business days after transactions are made.

                </span>,
                <span>
                  Should you require IT or Website Development Support, costs
                  will vary based on your specific needs. The YOOZ team can work
                  with your budget to develop a customized solution for you.
                </span>,
              ]}
            />
          </Grid>
          <Grid item>
            <ContentImageBlock
              title="READY TO GET STARTED?"
              titleSm="h4"
              titleLg="h3"
              titleXs="h6"
              imgPosition="right"
              color="#1CC5FF"
              list={
                <ContentList
                  items={[
                    <span>
                      <a
                        href={`${API_ROOT}document/yo-letter-of-interest`}
                        className={classes.yoozBlueLink}
                        target="_blank"
                      >
                        Click
                      </a>{" "}
                      to download the letter of interest and email it to{" "}
                      <a
                        href="mailto: yooz@yoozit.today"
                        className={classes.yoozBlueLink}
                      >
                        yooz@yoozit.today
                      </a>{" "}
                      along with your Onboarding documents. To see all required
                      documents for registration check out our{" "}
                      <a
                        href="/OnboardingDocuments"
                        className={classes.yoozBlueLink}
                      >
                        Onboarding Documents
                      </a>{" "}
                      page.
                    </span>,
                  ]}
                />
              }
              img={signUp_img}
            />
          </Grid>
        </Grid>
      </Fragment>
    </Container>
  );
}
