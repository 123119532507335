import icon1 from "assets/images/pg-icon1.png";
import icon2 from "assets/images/pg-icon2.png";
import icon3 from "assets/images/pg-icon3.png";
import icon4 from "assets/images/pg-icon4-secure-payment.png";
import icon6 from "assets/images/icon-online-shopping.png";
import icon5 from "assets/images/pg-icon5.png";

export const gatewayFeatures = [
  { text: "Accept payments on Checkout.", img: icon6 },
  {
    text: (
      <span>
         Accept payments without the hassle. Let <strong>YOOZ</strong> manage the online payment processing and security compliance requirements for you.
      </span>
    ),
    img: icon4,
  },
  { text: "No need for IT or expensive e-commerce sites.", img: icon3 },
  {
    text: "Supports REST API Integration.",
    img: icon1,
  },
  {
    text: "Applies record of customer payments to your accounting system in real time.",
    img: icon2,
  },
];
