import React, { PureComponent } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCountryList,
  getUserDetails,
  otpVarification,
  sendOtp,
  sendPwdResetLink,
  userLogin,
  twoFactorEnabled, sendEmailOtp,
} from "actions/auth";
import Login from "components/public-dashboard/features/login";
import { setLoading, showSnackBar } from "reducers/uiGlobal";
import {
  EMAIL_TIMEOUT_TIME,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  OTP_VARIFY_FAILURE,
  OTP_VARIFY_SUCCESS,
  PWDLINK_SENT_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_SUCCESS,
  SMS_TIMEOUT_TIME,
  TWO_FACTOR_ENABLED_OTP_FAILURE,
  TWO_FACTOR_ENABLED_OTP_SUCCESS,
  USER_TIMEOUT_PHONE, VERIFICATION_EMAIL_TIMEOUT_CODE, VERIFICATION_SMS_TIMEOUT_CODE, VERIFICATION_TIMEOUT_CODE,
} from "constants/actionTypes";
import history from "history/history";
import {
  removeUserAuth,
  removeUserDetail,
  setToken,
} from "middleware/storage";
import auth from "routing/checkAuth";
import {checkEmailRegex} from "components/utils";
import moment from "moment";
class Auth extends PureComponent {
  constructor(props) {
    super(props);
    const expirationTimes = this.getRestoreExpirationTime();
    this.state = {
      mobNum: expirationTimes.phone,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPwd: "",
      countryId: 0,
      isChecked: false,
      isVisible: false,
      otp: "",
      username: "",
      userPwd: "",
      emailForReset: "",
      isForgot: false,
      error: "",
      loginError: "",
      otpError: "",
      emailError: "",
      loading: false,
      otpType: "email",
      emailVerificationCode: expirationTimes.emailCode, // used if user refreshes page and not exist in memory
      smsVerificationCode: expirationTimes.smsCode, // used if user refreshes page and not exist in memory
      lastEmailOtpExpirationTime: expirationTimes.email,
      lastSMSOtpExpirationTime: expirationTimes.sms,
      timeoutDuration: 2,
    };
    this.inputRefs = Array(6).fill(React.createRef());
    this.inputOtp = Array(6).fill(React.createRef());
    this.props.dispatch(getCountryList()).then((res) => {});

  }

  updateLoading = (loading) => {
    this.props.dispatch(setLoading(loading));
  };

  onChangeFormField = (field, value) => {
    this.setState({ ...this.state, [field]: value });
  };

  validateLoginForm = () => {
    const { userPwd, username } = this.state;
    if (!username.length) {
      this.setState({ loginError: "*Username can not be empty." });
      return false;
    }
    if (!this.checkEmail(username)) {
      this.setState({ loginError: "Invalid email.Please enter a valid email" });
      return false;
    }
    if (!userPwd.length) {
      this.setState({ loginError: "Password can not be empty." });
      return false;
    }
    return true;
  };

  validateResetEmail = () => {
    const { emailForReset } = this.state;
    if (!emailForReset.length) {
      this.setState({ emailError: "Email can not be empty." });
      return false;
    }
    if (!this.checkEmail(emailForReset)) {
      this.setState({
        emailError: "Invalid email. Please enter a valid email.",
      });
      return false;
    }
    return true;
  };

  checkEmail = (email) => {
    let result = checkEmailRegex(email);
    return result;
  };

  checkPhone = (email) => {
    let regx = /^[+]?\d+$/;
    let result = regx.test(email) === true ? true : false;
    return result;
  };
  validateSignUpForm = () => {
    const {
      mobNum,
      firstName,
      lastName,
      email,
      password,
      confirmPwd,
      countryId,
      isChecked,
    } = this.state;
    if (mobNum.length > 13) {
      this.setState({ error: "Mobile Number should be greater then 12 digit" });
      return false;
    }
    if (!mobNum.length) {
      this.setState({ error: "Mobile Number can not be empty" });
      return false;
    }
    if (!firstName.length) {
      this.setState({ error: "Firstname can not be empty" });
      return false;
    }
    if (!lastName.length) {
      this.setState({ error: "Lastname can not be empty" });
      return false;
    }
    if (!email.length) {
      this.setState({ error: "Email can not  be empty" });
      return false;
    }
    if (!this.checkEmail(email)) {
      this.setState({ error: "Invalid email.Please enter a valid email" });
      return false;
    }
    if (!password.length) {
      this.setState({ error: "Password can not be empty" });
      return false;
    } else if (password.length < 8 || password.length > 12) {
      this.setState({ error: "Password length should be 8 to 12" });
      return false;
    }

    if (!confirmPwd.length) {
      this.setState({ error: "Please confirm the password" });
      return false;
    }
    if (password !== confirmPwd) {
      this.setState({ error: "Password is not matched.Please confirm again" });
      return false;
    }
    if (!isChecked) {
      this.setState({ error: "Please agree with terms and conditions" });
      return false;
    }
    if (countryId <= 0) {
      this.setState({ error: "Please select country" });
      return false;
    }
    return true;
  };

  setMobileOtp = (digit) => {
    this.setState({ otp: digit });
  };

  onOtpVarify = () => {
    const { otp, otpType, mobNum, smsVerificationCode, emailVerificationCode } = this.state;
    const { smsCred } = this.props;
    if (otp.length === 6) {
      const cred = {
        phone: smsCred.phone || mobNum,
        tempVerificationCodeId:otpType === "sms" ? smsVerificationCode : emailVerificationCode,
        sMSCode: otp,
      };

      removeUserAuth();
      removeUserDetail();
      this.updateLoading(true);
      this.props.dispatch(otpVarification(cred)).then((res) => {
        if (res.type === OTP_VARIFY_SUCCESS) {
          this.setState({ otpError: "" });
          this.updateExpirationTime(0, cred.phone, smsCred.tempVerificationCodeId, false, true);
          this.login();
        }

        if (res.type === OTP_VARIFY_FAILURE) {
          this.updateLoading(false);
          this.setState({ otpError: res.error });
        }
      });
    } else {
      this.updateLoading(false);
      this.setState({ otpError: "Please complete the OTP" });
    }
  };

  login = () => {
    const { tokens } = this.props;

    setToken(tokens.token);

    auth.giveAuthAccess();

    this.props.dispatch(getUserDetails(tokens.token)).then((res) => {
      this.updateLoading(false);
      history.push("/user", { isAuth: true });
    });
  };

  onLogin = (e) => {
    this.setState({ otpError: "" });

    e.preventDefault();
    this.setState({ loginError: "" });
    if (this.validateLoginForm()) {
      removeUserAuth();
      removeUserDetail();
      const { username, userPwd, lastEmailOtpExpirationTime } = this.state;
      const user = {
        username: username,
        password: userPwd,
      };
      this.updateLoading(true);
      this.props.dispatch(userLogin(user)).then((res) => {
        const { tokens } = this.props;
        if (res.type === LOGIN_SUCCESS && tokens) {
          this.setState({ loginError: "" });
          let cred = { phone: tokens.phoneNumber, isVerify: true };

          let currentTime = moment();

          if(lastEmailOtpExpirationTime !== null && currentTime.isBefore(lastEmailOtpExpirationTime)){
            this.setState({ isVerify: true });
            this.setPopupVisibility();
            this.updateLoading(false);
          }else {
            this.props.dispatch(sendEmailOtp(username, tokens.phoneNumber)).then((res) => {
              if (res.type === RESEND_OTP_SUCCESS) {
                this.updateLoading(false);
                if (res.entities.user.smsCred.twoFactor) {

                  this.updateExpirationTime(res.entities.user.smsCred.timeoutDuration, tokens.phoneNumber,  res.entities.user.smsCred.tempVerificationCodeId );
                  this.setState({ mobNum: tokens.phoneNumber, timeoutDuration: res.entities.user.smsCred.timeoutDuration});
                  this.setState({ isVerify: true });
                  this.setPopupVisibility();
                } else {
                  this.login();
                }
              }
              if (res.type === RESEND_OTP_FAILURE) {
                this.updateLoading(false);
                this.setState({ loginError: res.error });
              }
            });
          }

        }

        if (res.type === LOGIN_FAILURE) {
          this.updateLoading(false);
          this.setState({ loginError: res.error });
        }
      });
    }
  };

  setOtpType = (type) =>{
    this.setState({ otpType: type,});
  }


  sendEmailOtp = (email, mobile) =>{
    const {lastEmailOtpExpirationTime } = this.state;
    let currentTime = moment();
    if(lastEmailOtpExpirationTime !== null && currentTime.isBefore(lastEmailOtpExpirationTime)){
      return;
    }
    this.props.dispatch( sendEmailOtp(email, mobile)).then((res) => {
      if(res.type === RESEND_OTP_SUCCESS){
        this.updateExpirationTime(res.entities.user.smsCred.timeoutDuration, mobile, res.entities.user.smsCred.tempVerificationCodeId);
      }else if (res.type === RESEND_OTP_FAILURE) {
        this.props.dispatch(showSnackBar({
          msg: "There was an issue sending the OTP code",
          type: "error",
          autoHideDuration: 30000,
        }))
      }
    });
  }

  sendSMSOtp = (phone) => {
    const {lastSMSOtpExpirationTime } = this.state;
    let currentTime = moment();
    if(lastSMSOtpExpirationTime !== null && currentTime.isBefore(lastSMSOtpExpirationTime)){
      return;
    }
    this.props. dispatch(sendOtp({Phone: phone, isVerify: true})).then((res) => {
      if(res.type === RESEND_OTP_SUCCESS){
        this.updateExpirationTime(res.entities.user.smsCred.timeoutDuration, phone,  res.entities.user.smsCred.tempVerificationCodeId);
      }else if  (res.type === RESEND_OTP_FAILURE) {
        this.props.dispatch(showSnackBar({
          msg: "There was an issue sending the OTP code",
          type: "error",
          autoHideDuration: 30000,
        }))
      }
    });
  }


  getRestoreExpirationTime = () => {
    let smsTimeout = localStorage.getItem(SMS_TIMEOUT_TIME);
    let userPhone =  localStorage.getItem(USER_TIMEOUT_PHONE);
    let emailTimeout = localStorage.getItem(EMAIL_TIMEOUT_TIME);
    let verificationEmailCode = localStorage.getItem(VERIFICATION_EMAIL_TIMEOUT_CODE);
    let verificationSMSCode = localStorage.getItem(VERIFICATION_SMS_TIMEOUT_CODE);

    return {
      sms : EMAIL_TIMEOUT_TIME ? moment(smsTimeout) : null,
      email : SMS_TIMEOUT_TIME ? moment(emailTimeout) : null,
      phone: userPhone || "",
      smsCode: verificationSMSCode ? parseInt(verificationSMSCode) : "",
      emailCode: verificationEmailCode ? parseInt(verificationEmailCode) : ""
    }
  }

  updateExpirationTime = (minutes, phone, verificationCode, shouldReset = false, resetAll = false) =>{
    const { otpType } = this.state;

    if(resetAll){
      localStorage.removeItem(SMS_TIMEOUT_TIME);
      localStorage.removeItem(EMAIL_TIMEOUT_TIME);
      localStorage.removeItem(USER_TIMEOUT_PHONE);
      localStorage.removeItem(VERIFICATION_SMS_TIMEOUT_CODE);
      localStorage.removeItem(VERIFICATION_EMAIL_TIMEOUT_CODE);
      this.setState({ lastEmailOtpExpirationTime: null, lastSMSOtpExpirationTime: null, emailVerificationCode: "", smsVerificationCode:"" });

      return;
    }

    if(shouldReset){
      if(otpType == "sms")
        this.setState({ lastSMSOtpExpirationTime: null });
      else
        this.setState({ lastEmailOtpExpirationTime: null });
    }

    const otpExpiration = moment().add(minutes, 'minutes');
    if(otpType === "sms"){
      localStorage.setItem(SMS_TIMEOUT_TIME, otpExpiration.toISOString());
      localStorage.setItem(VERIFICATION_SMS_TIMEOUT_CODE, verificationCode);
      this.setState({ lastSMSOtpExpirationTime: otpExpiration, smsVerificationCode: verificationCode });
    }
    else{
      localStorage.setItem(EMAIL_TIMEOUT_TIME, otpExpiration.toISOString());
      localStorage.setItem(VERIFICATION_EMAIL_TIMEOUT_CODE, verificationCode);
      this.setState({ lastEmailOtpExpirationTime: otpExpiration,emailVerificationCode: verificationCode });
    }
    localStorage.setItem(USER_TIMEOUT_PHONE, phone);
  }

  resendOtp = () => {
    this.setState({ otp: "" });
    this.setState({ otpError: "" });

    const { mobNum, username: email, isVerify, otpType, timeoutDuration, lastSMSOtpExpirationTime, lastEmailOtpExpirationTime} = this.state;
    let isVerifyed = false;
    if (isVerify) {
      if (isVerify === true) {
        isVerifyed = true;
      } else {
        isVerifyed = false;
      }
    }

    let cred = { phone: mobNum, email: email, isVerify: isVerifyed };
    this.updateLoading(true);
    this.props.dispatch(otpType === "sms" ?  sendOtp(cred) : sendEmailOtp(email, cred.phone)).then((res) => {
      if (res.type === RESEND_OTP_SUCCESS) {
        this.updateExpirationTime(res.entities.user.smsCred.timeoutDuration, cred.phone, res.entities.user.smsCred.tempVerificationCodeId, true);

        this.updateLoading(false);
        this.props.dispatch(
          showSnackBar({
            msg: otpType === "sms" ? "A SMS verification code has been sent to: " + mobNum : "A email verification code has been sent to: " + email,
            type: "info",
            autoHideDuration: 10000,
          })
        );
      }
      if (res.type === RESEND_OTP_FAILURE) {
        this.updateLoading(false);
        this.setState({ otpError: res.error });
      }
    });
  };

  setPopupVisibility = () => {
    const { isVisible } = this.state;
    this.setState({ isVisible: !isVisible, otp: "", otpError: "" });
  };

  setForgotPwdisibility = () => {
    const { isForgot } = this.state;
    this.setState({ isForgot: !isForgot });
  };

  onChangeFormField = (field, value) => {
    this.setState({ ...this.state, [field]: value });
  };

  sendResetLink = () => {
    const { emailForReset } = this.state;
    if (this.validateResetEmail()) {
      this.props.dispatch(sendPwdResetLink(emailForReset)).then((res) => {
        if (res.type === PWDLINK_SENT_SUCCESS) {
          this.setState({ emailError: "" });
          this.setForgotPwdisibility();
          this.props.dispatch(
            showSnackBar({
              msg: "Password reset link has been sent to: " + emailForReset,
              type: "info",
              autoHideDuration: 30000,
            })
          );
        }
      });
    }
  };

  setLoginOtpVarify = () => {
    const { isLoginOtpVarifyVisible } = this.state;
    this.setState({
      isLoginOtpVarifyVisible: !isLoginOtpVarifyVisible,
      otp: "",
    });
  };

  onLoginOtpVarify = () => {
    const { otp, otpType, mobNum, smsVerificationCode, emailVerificationCode } = this.state;
    const { tokens, smsCred } = this.props;
    if (otp.replace(/ /g, "").length === 6) {
      const cred = {
        phone: smsCred.phone || mobNum,
        tempVerificationCodeId: smsCred.tempVerificationCodeId || (otpType === "sms" ? smsVerificationCode : emailVerificationCode) ,
        sMSCode: otp,
      };

      this.props.dispatch(otpVarification(cred)).then((res) => {
        if (res.type === OTP_VARIFY_SUCCESS) {
          this.setState({ otpError: "",  });
          this.updateExpirationTime(0, cred.phone,  smsCred.tempVerificationCodeId, false, true);

          setToken(tokens.token);

          auth.giveAuthAccess();
          this.props.dispatch(getUserDetails(tokens.token)).then((res) => {
            history.push("/user", { isAuth: true });
          });
        }

        if (res.type === OTP_VARIFY_FAILURE) {
          this.setState({ otpError: res.error });
        }
      });
    }
  };

  render() {
    return (
      <Login
        {...this.props}
        {...this.state}
        onOtpVarify={this.onOtpVarify}
        resendOtp={this.resendOtp}
        sendEmailOtp={this.sendEmailOtp}
        sendSMSOtp={this.sendSMSOtp}
        setOtpType={this.setOtpType}
        setMobileOtp={this.setMobileOtp}
        onChangeFormField={this.onChangeFormField}
        setVisibility={this.setPopupVisibility}
        inputOtp={this.inputOtp}
        setForgotPwdisibility={this.setForgotPwdisibility}
        onLogin={this.onLogin}
        sendResetLink={this.sendResetLink}
        onClickMobileMenu={this.onClickMobileMenu}
        setLoginOtpVarify={this.setLoginOtpVarify}
        onLoginOtpVarify={this.onLoginOtpVarify}
      />
    );
  }
}

function mapStateToProps(state) {
  const { authReducer, entities } = state;
  return {
    authReducer,
    tokens: entities.user.tokens,
    smsCred: entities.user.smsCred,
    details: entities.user.details,
    countries: entities.user.countries,
    validString: entities.user.queryString,
  };
}

export default connect(mapStateToProps)(Auth);
