import { Container, Divider, Grid, useMediaQuery } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import yooz_scan2pay from "assets/images/YOOZScan2Pay-Image-to-resize-and-reformat_2.png";
import yooz_agent from "assets/images/YOOZ_AGENT.png";
import yooz_online from "assets/images/YOOZ_ONLINE.png";
import yooz_payments from "assets/images/YOOZ_PAYMENTS.png";
import Accessible from "assets/images/accessible_donors.png";
import EasyDoners from "assets/images/easy_donors_icon.png";
import signUp_img from "assets/images/get_started_2.png";
import icon4 from "assets/images/pg-icon4-secure-payment.png";
import StreamLine from "assets/images/stream_line.png";
import TextList from "components/public-dashboard/components/TextList";
import ContentImageBlock from "components/public-dashboard/components/contentImageBlock";
import ContentList from "components/public-dashboard/components/contentList";
import IconHeader from "components/public-dashboard/components/iconHeader";
import PrimaryText from "components/public-dashboard/components/primaryText";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import { API_ROOT } from "constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  logoHeading: {
    width: 150,
    [theme.breakpoints.up("sm")]: {
      width: 180,
    },
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
    minWidth: 100,
  },
  body: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 70,
      marginBottom: theme.spacing(10),
    },
  },
  overlineText: {
    display: "flex",
    alignItems: "left",
    flexWrap: "wrap",
    fontFamily: "Akzidenz-Grotesk BQ, serif",
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    textTransform: "uppercase",
  },
  it: {
    fontStyle: "italic",
    fontFamily: "Arial Rounded MT",
    textTransform: "none",
  },
  right: {
    textAlign: "right",
  },
  img: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "75%",
  },
  ol: {
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  collectPayment: {
    color: "#008BC3",
  },

  yoozBlueLink: {
    color: "#00AFE2",
  },
  helpYouText: {
    fontSize: 38,
  },
  featureImg: {
    [theme.breakpoints.up("md")]: {
      height: 110,
      width: 110,
    },
    height: 30,
    width: 30,
  },
  gatewayFeatures: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 40,
      marginBottom: 48,
    },
    paddingLeft: 16,
  },
}));

export default function PartnersContent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const gatewayFeatures = [
    { text: "Make it easier for Donors to pay you ANYTIME.", img: EasyDoners },
    {
      text: "Streamline and automate your accounting process.",
      img: StreamLine,
    },
    { text: "Be more accessible to Donors.", img: Accessible },
    {
      text: (
          <span>
          Accept payments without the hassle. Let <strong>YOOZ</strong> manage the online payment processing and security compliance requirements for you.
        </span>
      ),
      img: icon4,
    },
  ];
  const LinkText = (props) => {
    const { navIndex, route, text } = props;
    return (
      <Link
        onClick={() => {
          window.scrollTo(0, 0);
          dispatch(setTabPosition(navIndex));
        }}
        component={RouterLink}
        to={route}
        className={classes.linktext}
      >
        <b>{text}</b>
      </Link>
    );
  };
  const contentList = () => {
    return [
      {
        title: "Online",
        color: "grey",
        img: yooz_online,
        lines: [
          <span>
            Accept debit and credit card payments via our secure payment portal.
            Donors can pay you quickly, safely and easily through our
            <LinkText navIndex={7} route="/quickpay" text=" Quick Pay" />,{" "}
            <LinkText navIndex={7} route="/scan2pay" text=" Scan2Pay" /> and{" "}
            <LinkText navIndex={7} route="/login" text="Login" /> features.
          </span>,
        ],
      },
      {
        title: "AGENTS",
        color: "#57B56B",
        img: yooz_agent,
        lines: [
          <span>
            Take advantage of our extensive agent network allowing donors to
            make donations to your charity at any of our
            150+ Authorized
            <LinkText
              navIndex={7}
              route="/AgentLocations"
              text=" YOOZ Bill Payment Agents"
            />{" "}
            located throughout Trinidad and Tobago.
          </span>,
        ],
      },
      {
        title: "SCAN2PAY",
        color: "#BD2126",
        img: yooz_scan2pay,
        lines: [
          <span>
            Leverage QR Codes and Payment Links to accept payments at charity
            events, on printed ads, your social pages and website. Avoid the
            hassle of monthly fees, terminals, online transfers, hidden fees and
            payment delays with{" "}
            <LinkText navIndex={7} route="/scan2pay" text="YOOZ Scan2Pay" />.
          </span>,
        ],
      },
      {
        title: "PAYMENT <br> GATEWAY",
        color: "#A43168",
        img: yooz_payments,
        lines: [
          <span>
            Boost your donations and start accepting payments online by
            integrating the
            <LinkText
              navIndex={7}
              route="/payment-gateway"
              text=" YOOZ Payment Gateway "
            />{" "}
            into your own website and let YOOZ manage the online payment processing and security compliance requirements for you.
          </span>,
        ],
      },
    ];
  };
  return (
    <Container>
      <Fragment>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item container xs={12} sm={12}>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              className={classes.gatewayFeatures}
            >
              {gatewayFeatures.map((x, index) => (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  key={"gateway-features-" + index}
                  spacing={2}
                  style={{ paddingBottom: theme.spacing(3) }}
                >
                  <Grid item xs={2}>
                    <img src={x.img} className={classes.featureImg}></img>
                  </Grid>
                  <Grid item xs={10}>
                    <PrimaryText text={x.text} />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <TextList
            items={[
              <span>
                <h3>
                  Secure, quick and easy to use, YOOZ makes it easy to collect
                  donations through multiple channels:
                </h3>
              </span>,
            ]}
          />
        </Grid>

        {contentList().map((c, index) => (
          <Fragment key={"content-" + index}>
            <ContentImageBlock
              titleSm="h2"
              titleLg="h1"
              titleXs="h6"
              imgPosition={index % 2 == 0 ? "left" : "right"}
              special={
                <IconHeader
                  title={c.title}
                  iconColor="#000"
                  titleColor={c.color}
                  titleLg="h2"
                  titleSm="h2"
                  titleXs="h6"
                />
              }
              list={<TextList items={c.lines} />}
              img={c.img}
            />
            {xs && <Divider variant="middle" />}
          </Fragment>
        ))}

        <ContentList
          title="WHAT'S THE COST?"
          items={[
            <span>
              We operate on a per transaction pricing model, which means you pay
              only when you get paid!
            </span>,
            <span>
              To Charities and NGOs, we offer a special rate of 2% (bank fees) +
              $2.25 per transaction. Collections are remitted via ACH at a cost of $1.00 per bulk
transfer, 3 business days after transactions are made.
            </span>,
          ]}
        />
        <ContentImageBlock
          title="READY TO GET STARTED?"
          titleSm="h4"
          titleLg="h3"
          titleXs="h6"
          imgPosition="left"
          img={signUp_img}
          color="#1CC5FF"
          list={
            <ContentList
              items={[
                <span>
                  <a
                    href={`${API_ROOT}document/yo-letter-of-interest`}
                    className={classes.yoozBlueLink}
                    target="_blank"
                  >
                    Click
                  </a>{" "}
                  to download the letter of interest and email it to{" "}
                  <a
                    href="mailto: yooz@yoozit.today"
                    className={classes.yoozBlueLink}
                  >
                    yooz@yoozit.today
                  </a>{" "}
                  along with your Onboarding documents. To see all required
                  documents for registration check out our{" "}
                  <a
                    href="/OnboardingDocuments"
                    className={classes.yoozBlueLink}
                  >
                    Onboarding Documents
                  </a>{" "}
                  page.
                </span>,
              ]}
            />
          }
        />
      </Fragment>
    </Container>
  );
}
