import TextField from "@material-ui/core/TextField";
import React from "react";
import NumberFormat from "react-number-format";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="#   #   #   #   #   #"
      placeholder="_   _   _   _   _   _"
      mask={["_  ", "_  ", "_  ", "_  ", "_  ", "_"]}
    />
  );
}

export default function MaskedVerificationCode(props) {
  const { value, handleChange, error } = props;
  const handleFocus = (event) => event.target.select();

  return (
    <TextField
      value={value}
      label="Verification Code"
      onFocus={handleFocus}
      onChange={handleChange}
      variant="outlined"
      fullWidth
      error={error && error.length > 0}
      helperText={error && error.length > 0 ? error : ""}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      color="secondary"
      required
      id="smsCode"
    />
  );
}
